import { OnInit, OnDestroy } from '@angular/core';
import { IdentifyingClient } from '../../shared/api/identifying-api.generated';
import { AdministratorClient } from '../../shared/api/administrator-api.generated';
import { Subscription, combineLatest, BehaviorSubject, of as _observableOf } from 'rxjs';
import { filter, switchMap, map, distinctUntilChanged, catchError, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { PortalService } from '../../core/services/portal.service';
import { environment } from '../../../environments/environment';
import { BatchActionService, BatchSelectionType } from '../../core/services/batch-action.service';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { ReflectionsFilterService } from '../reflections-filter.service';
var ReflectionsComponent = /** @class */ (function () {
    function ReflectionsComponent(identifyingClient, administratorClient, userSettingsService, portalService, batchActionService, modalService, translate, reflectionsFilterService) {
        this.identifyingClient = identifyingClient;
        this.administratorClient = administratorClient;
        this.userSettingsService = userSettingsService;
        this.portalService = portalService;
        this.batchActionService = batchActionService;
        this.modalService = modalService;
        this.translate = translate;
        this.reflectionsFilterService = reflectionsFilterService;
        this.page = 1;
        this.pageSize = environment.api.pageSize;
        this.reflectionScans = [];
        this.subscriptions = [];
        this.fetch$ = new BehaviorSubject(false);
        this.sortOn = '-createdOn';
    }
    ReflectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var reflectionsSub = combineLatest(this.fetch$, this.reflectionsFilterService.filter$, this.userSettingsService.currentAccount$, this.portalService.expectations$, this.portalService.domains$)
            .pipe(filter(function (_a) {
            var fetch = _a[0], reflectionsFilter = _a[1], account = _a[2], expectations = _a[3], domains = _a[4];
            return fetch && !!account && !!expectations && !!domains;
        }), debounceTime(500), switchMap(function (_a) {
            var fetch = _a[0], reflectionsFilter = _a[1], account = _a[2], expectation = _a[3], domains = _a[4];
            var offset = (_this.page - 1) * _this.pageSize;
            _this.loading = true;
            return _this.administratorClient.getJobScansForAccountAndClientType(account.id, 7, undefined, /* externalRef */ undefined /* shouldHaveExpectations */, true /* includeExpectations */, reflectionsFilter.teamRoleIds, reflectionsFilter.workValueIds, offset, _this.pageSize, _this.sortOn, reflectionsFilter.search, reflectionsFilter.domainIds).pipe(map(function (reflectionScans) {
                reflectionScans.forEach(function (jp) {
                    jp.expectations = _this.mapExpectations(jp, expectation);
                    jp.domains = _this.mapDomains(jp, domains);
                });
                return reflectionScans;
            }), catchError(function (err) { return _observableOf([]); }));
        })).subscribe(function (reflectionScans) {
            _this.totalCount = reflectionScans.length;
            _this.loading = false;
            Array.prototype.push.apply(_this.reflectionScans, reflectionScans);
            _this.fetch$.next(false);
        });
        var accountSub = this.userSettingsService
            .currentAccount$
            .pipe(filter(function (p) { return !!p; }), distinctUntilChanged())
            .subscribe(function (account) { return _this.reset(); });
        var batchDeleteSub = this.batchActionService
            .delete$
            .pipe(filter(function (res) { return res.selectionType === BatchSelectionType.Reflections; }))
            .subscribe(function (res) { return _this.showBatchDeleteConfirmationModal(res.selection); });
        var batchActionBarVisibleSub = this.batchActionService
            .actionBarVisible$
            .subscribe(function (actionBarVisible) { return _this.actionBarVisible = actionBarVisible; });
        var filterSub = this.reflectionsFilterService.filter$
            .subscribe(function (reflectionScansFilter) {
            _this.filter = reflectionScansFilter;
            _this.reset();
        });
        var totalCountSub = combineLatest(this.userSettingsService.currentAccount$, this.reflectionsFilterService.filter$)
            .pipe(filter(function (_a) {
            var account = _a[0], reflectionScansFilter = _a[1];
            return !!account;
        }), switchMap(function (_a) {
            var account = _a[0], reflectionScansFilter = _a[1];
            return _observableOf(_this.totalCount);
        })).subscribe(function (totalCount) { return _this.totalCount = totalCount; });
        this.subscriptions.push(reflectionsSub);
        this.subscriptions.push(accountSub);
        this.subscriptions.push(batchDeleteSub);
        this.subscriptions.push(batchActionBarVisibleSub);
        this.subscriptions.push(filterSub);
        this.subscriptions.push(totalCountSub);
    };
    ReflectionsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    ReflectionsComponent.prototype.getReflectionScans = function () {
        this.page++;
        this.fetch$.next(true);
    };
    ReflectionsComponent.prototype.sort = function (sortOn) {
        this.sortOn = sortOn;
        this.reset();
    };
    ReflectionsComponent.prototype.onDelete = function (reflectionScan) {
        var index = this.reflectionScans.indexOf(reflectionScan);
        if (index >= 0) {
            this.reflectionScans.splice(index, 1);
            this.totalCount--;
        }
    };
    ReflectionsComponent.prototype.workValuesChanged = function (workValues) {
        this.filter.workValues = workValues;
        this.reflectionsFilterService.filter$.next(this.filter);
    };
    ReflectionsComponent.prototype.teamRolesChanged = function (teamRoles) {
        this.filter.teamRoles = teamRoles;
        this.reflectionsFilterService.filter$.next(this.filter);
    };
    ReflectionsComponent.prototype.domainsChanged = function (domains) {
        this.filter.domains = domains;
        this.reflectionsFilterService.filter$.next(this.filter);
    };
    ReflectionsComponent.prototype.clearSearch = function () {
        this.filter.search = '';
        this.searchChanged('');
    };
    ReflectionsComponent.prototype.searchChanged = function (search) {
        this.reflectionsFilterService.filter$.next(this.filter);
    };
    ReflectionsComponent.prototype.reset = function () {
        this.batchActionService.deSelectAll();
        this.page = 1;
        this.reflectionScans.splice(0);
        this.totalCount = 0;
        this.fetch$.next(true);
    };
    ReflectionsComponent.prototype.showBatchDeleteConfirmationModal = function (reflectionScanIds) {
        var _this = this;
        var count = reflectionScanIds.length;
        var header = this.translate.instant('jobscans.delete_multiple_modal_header', { count: count });
        var message = this.translate.instant('jobscans.delete_multiple_modal_content', {
            count: count,
            accountName: this.userSettingsService.currentAccount$.getValue().name
        });
        var props = new DeleteModalProps(header, message);
        var initialState = { props: props };
        this.modalService.show(DeleteModalComponent, { initialState: initialState });
        var deleteSub = props.confirm$.subscribe(function () { return _this.deleteReflectionScans(reflectionScanIds); });
        this.subscriptions.push(deleteSub);
    };
    ReflectionsComponent.prototype.deleteReflectionScans = function (reflectionScanIds) {
        var _this = this;
        this.identifyingClient.deleteJobScans(reflectionScanIds.join(','))
            .subscribe(function () {
            _this.batchActionService.deSelectAll();
            _this.batchActionService.actionBarVisible$.next(false);
            reflectionScanIds.forEach(function (id) {
                var reflectionScan = _this.reflectionScans.filter(function (t) { return t.id === id; })[0];
                _this.onDelete(reflectionScan);
            });
        });
    };
    ReflectionsComponent.prototype.mapExpectations = function (reflectionScan, allExpectations) {
        if (!reflectionScan.expectations) {
            return [];
        }
        var lang = this.translate.currentLang;
        return reflectionScan.expectations.map(function (exp) {
            var translatedExpectation = allExpectations.filter(function (c) { return c.id === exp.expectationId; })[0];
            exp.expectationTypeId = translatedExpectation.expectationTypeId;
            exp.name = translatedExpectation.name[lang];
            exp.iconUrl = translatedExpectation.iconUrl;
            return exp;
        });
    };
    ReflectionsComponent.prototype.mapDomains = function (reflectionScan, allDomains) {
        if (!reflectionScan.domainIds) {
            return [];
        }
        return reflectionScan.domainIds.map(function (domainId) { return allDomains.filter(function (dom) { return dom.id === domainId; })[0]; });
    };
    return ReflectionsComponent;
}());
export { ReflectionsComponent };
